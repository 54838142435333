import "./StarIcon.css";

import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export function StarIcon() {
  return (
    <Link to="/vehicles/favorite">
      <OverlayTrigger placement="top" overlay={<Tooltip>Ulubiony</Tooltip>}>
        <div className="star">★</div>
      </OverlayTrigger>
    </Link>
  );
}
