import { Mode, Passage, useStopPassages } from "../hooks/useStopPassages";

import { Countdown } from "./Countdown";
import { Delay } from "./Delay";
import { Error } from "./Error";
import { KmkId } from "./KmkId";
import { Link } from "react-router-dom";
import { LiveIcon } from "./LiveIcon";
import { RouteShortNameBox } from "./RouteShortNameBox";
import { RouteShortNameBoxPlaceholder } from "./RouteShortNameBoxPlaceholder";
import Spinner from "react-bootstrap/Spinner";
import { StarIcon } from "./StarIcon";
import { StopLink } from "./StopLink";
import { Strikethrough } from "./Strikethrough";
import Table from "react-bootstrap/Table";
import { getStopUrl } from "../utils";
import { useFavoriteVehicles } from "../hooks/useFavoriteVehicles";
import Alert from "react-bootstrap/Alert";

function isPassageLive(passage: Passage) {
  return passage.status !== "PLANNED";
}

interface StopPassagesProps {
  stopName: string;
  mode: Mode;
}

export function StopPassages({ stopName, mode }: StopPassagesProps) {
  const { passages, loading, error } = useStopPassages(stopName, mode, 10_000);

  const { isFavorite } = useFavoriteVehicles();

  if (error?.message === "NOT FOUND") {
    return <Alert variant="secondary">Nie znaleziono przystanku.</Alert>;
  }

  if (error && error?.message) {
    return <Error error={error} />;
  }

  return (
    <Table
      size="sm"
      bordered
      hover
      className="table-td-align-middle table-layout-fixed"
    >
      <thead>
        <tr>
          <th style={{ width: "20%" }} className="text-center">
            Czas do {mode === "departures" ? "odjazdu" : "przyjazdu"}
          </th>
          <th style={{ width: "10%" }} className="text-center">
            Linia
          </th>
          <th>{mode === "departures" ? "Kierunek" : "Z kierunku"}</th>
          <th style={{ width: "15%" }} className="text-center">
            {mode === "departures" ? "Odjazd" : "Przyjazd"}
          </th>
          <th style={{ width: "15%" }} className="text-center">
            Pojazd
          </th>
        </tr>
      </thead>
      <tbody>
        {passages.map((passage) => (
          <tr
            key={`${passage.passage_id}_${passage.kmk_id}`}
            className={
              passage.old
                ? "bg-dark bg-opacity-10 opacity-75"
                : passage.status === "STOPPING"
                ? "bg-warning bg-opacity-25"
                : undefined
            }
          >
            <td className="text-center">
              {passage.status === "DEPARTED" || passage.old ? (
                "✓"
              ) : (
                <Countdown timestamp={passage.timestamp} />
              )}
              {isPassageLive(passage) && <LiveIcon />}
            </td>
            <td className="text-center">
              {passage.route_short_name ? (
                <RouteShortNameBox routeShortName={passage.route_short_name} />
              ) : (
                <RouteShortNameBoxPlaceholder />
              )}
            </td>
            <td className="text-truncate">
              <Strikethrough enabled={passage.old}>
                <StopLink stopName={passage.trip_headsign} removeNz />
              </Strikethrough>
            </td>
            <td className="text-center">
              <Strikethrough enabled={passage.old}>
                {passage.planned_time}
              </Strikethrough>
              <Delay delay={passage.delay} />
            </td>
            <td className="text-center">
              {passage.kmk_id && <KmkId kmk_id={passage.kmk_id} />}
              {passage.kmk_id && isFavorite(passage.kmk_id) && <StarIcon />}
            </td>
          </tr>
        ))}
        {loading && (
          <tr>
            <td colSpan={5} className="py-3 text-center">
              <Spinner animation="border" variant="primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </td>
          </tr>
        )}
        {!loading && passages.length === 0 && (
          <tr>
            <td colSpan={5} className="py-3 text-center text-secondary">
              Brak {mode === "departures" ? "odjazdów" : "przyjazdów"}.
              <br />
              <Link
                to={getStopUrl(
                  stopName,
                  mode === "departures" ? "arrivals" : "departures"
                )}
                className="text-secondary"
              >
                Pokaż {mode === "departures" ? "przyjazdy do" : "odjazdy z"}{" "}
                tego przystanku
              </Link>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
