import Alert from "react-bootstrap/Alert";

function ReloadLink() {
  return (
    <span
      className="link-primary text-decoration-underline"
      role="button"
      onClick={() => window.location.reload()}
    >
      Spróbuj ponownie
    </span>
  );
}

interface ErrorProps {
  error: Error;
}

export function Error({ error }: ErrorProps) {
  if (error.message === "SERVICE UNAVAILABLE") {
    return (
      <Alert variant="info">
        Trwa pobieranie danych. <ReloadLink />
      </Alert>
    );
  }

  return (
    <Alert variant="danger">
      Wystąpił błąd: <code>{error.message}</code> <ReloadLink />
    </Alert>
  );
}
