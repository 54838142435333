import { useInterval } from "usehooks-ts";
import { useState } from "react";

interface CounterProps {
  timestamp: number;
}

export function Counter({ timestamp }: CounterProps) {
  const [now, setNow] = useState(Date.now());

  useInterval(() => setNow(Date.now()), 1000);

  const seconds = Math.round(now / 1000 - timestamp);

  if (seconds <= 0) {
    return <span className="nobr">teraz</span>;
  }

  if (seconds < 60) {
    return <span className="nobr">{seconds} s temu</span>;
  }

  if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return (
      <span className="nobr">
        {minutes} min {seconds % 60} s temu
      </span>
    );
  }

  if (seconds < 86400) {
    const minutes = Math.floor(seconds / 60) % 60;
    const hours = Math.floor(Math.floor(seconds / 60) / 60);
    return (
      <span className="nobr">
        {hours} h {minutes} min {seconds % 60} s temu
      </span>
    );
  }

  const minutes = Math.floor(seconds / 60) % 60;
  const hours = Math.floor(Math.floor(seconds / 60) / 60) % 24;
  const days = Math.floor(Math.floor(Math.floor(seconds / 60) / 60) / 24);
  return (
    <span className="nobr">
      {days} d {hours} h {minutes} min {seconds % 60} s temu
    </span>
  );
}
