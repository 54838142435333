import Container from "react-bootstrap/Container";
import PageTitle from "../components/PageTitle";

const AUTHOR_EMAIL = "tomekzawadzki98+gtfs@gmail.com";

interface ExternalLinkProps {
  url: string;
}

function ExternalLink({ url }: ExternalLinkProps) {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {url}
    </a>
  );
}

interface LinksProps {
  urls: string[];
}

function Links({ urls }: LinksProps) {
  return (
    <ul>
      {urls.map((url) => (
        <li key={url}>
          <ExternalLink url={url} />
        </li>
      ))}
    </ul>
  );
}

export function About() {
  return (
    <Container>
      <PageTitle title="Informacje" />
      <h1 className="my-4">Informacje</h1>
      <h4>Źródła danych</h4>
      <p>
        Niniejsza strona konsoliduje dane udostępniane przez Zarząd Transportu
        Publicznego w Krakowie oraz Miejskie Przedsiębiorstwo Komunikacyjne S.A.
        w Krakowie:
      </p>
      <Links
        urls={[
          "https://gtfs.ztp.krakow.pl/",
          "http://www.ttss.krakow.pl/internetservice/",
          "http://ttss.mpk.krakow.pl/internetservice/",
          "http://kokon.mpk.krakow.pl/",
        ]}
      />
      <p>
        Spis numerów taborowych pochodzi ze strony{" "}
        <ExternalLink url="https://psmkms.krakow.pl/ciekawostki/krakowski-tabor" />
        .
      </p>
      <h4>Polecane strony</h4>
      <Links
        urls={[
          "https://krakowpodreka.pl/pl/",
          "https://mpk.jacekk.net/",
          "https://beta.ttss.pl/map.html",
          "https://pkk.info.pl/viewtopic.php?f=100&t=4882",
          "https://forum.pkk.net.pl/viewtopic.php?t=65",
          "https://krabok.wordpress.com/tag/ttss/",
        ]}
      />
      <h4>Kontakt z autorem</h4>
      <p>
        Tomasz Zawadzki &lt;
        <a href={`mailto:${AUTHOR_EMAIL}`} target="_blank" rel="noreferrer">
          {AUTHOR_EMAIL}
        </a>
        &gt;
      </p>
    </Container>
  );
}
