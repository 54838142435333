import Form from "react-bootstrap/Form";
import { Service } from "../hooks/useBlocks";
import { formatWeekdays } from "../utils";

interface ServiceSelectProps {
  services: Service[];
  serviceId: number | undefined;
  setServiceId: (serviceId: number) => void;
  disabledFunc?: (serviceId: number) => boolean;
}

export function ServiceSelect({
  services,
  serviceId,
  setServiceId,
  disabledFunc,
}: ServiceSelectProps) {
  return (
    <>
      {services.map((service) => (
        <Form.Group
          controlId={`service_${service.service_id}`}
          key={`service_${service.service_id}`}
        >
          <Form.Check
            type="radio"
            key={service.service_id}
            value={service.service_id}
            label={formatWeekdays(service)}
            checked={service.service_id === serviceId}
            onChange={(e) => setServiceId(Number.parseInt(e.target.value))}
            disabled={disabledFunc?.(service.service_id)}
          />
        </Form.Group>
      ))}
    </>
  );
}
