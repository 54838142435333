import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

export function Navigation() {
  return (
    <Navbar bg="light" expand="lg" sticky="top" collapseOnSelect>
      <Container>
        <Navbar.Brand as={Link} to="/">
          Komunikacja Miejska{" "}
          <span className="d-none d-md-inline-block">w Krakowie</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/map" eventKey="0">
              Mapa
            </Nav.Link>
            <Nav.Link as={Link} to="/routes" eventKey="0">
              Linie
            </Nav.Link>
            <Nav.Link as={Link} to="/stops" eventKey="0">
              Przystanki
            </Nav.Link>
            <Nav.Link as={Link} to="/routes/vehicles" eventKey="0">
              Pojazdy
            </Nav.Link>
            <Nav.Link as={Link} to="/vehicles/active" eventKey="0">
              Kursy
            </Nav.Link>
            <Nav.Link as={Link} to="/blocks" eventKey="0">
              Brygady
            </Nav.Link>
            <Nav.Link as={Link} to="/trips/hidden" eventKey="0">
              Zjazdy/wyjazdy
            </Nav.Link>
            <Nav.Link as={Link} to="/vehicles/favorite" eventKey="0">
              Ulubione
            </Nav.Link>
            <Nav.Link as={Link} to="/status" eventKey="0">
              Status
            </Nav.Link>
            <Nav.Link as={Link} to="/about" eventKey="0">
              Informacje
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
