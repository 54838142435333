import { useEffect, useState } from "react";
import { useFetch, useLocalStorage } from "usehooks-ts";

import { BACKEND_BASE_URL } from "../config";
import { Service } from "./useBlocks";
import { compareRouteShortName } from "../utils";

export interface HiddenTrip {
  block_id: number;
  trip_num: number;
  service_id: number;
  route_short_name: string;
  trip_headsign: string;
  departure_time: string;
  first_stop_name: string | null;
  arrival_time: string | null;
  last_stop_name: string | null;
  category: string;
}

function sortHiddenTrips(hiddenTrips: HiddenTrip[]) {
  const copy = Array.from(hiddenTrips);
  copy.sort(
    (a, b) =>
      compareRouteShortName(a.route_short_name, b.route_short_name) ||
      a.departure_time.localeCompare(b.departure_time)
  );
  return copy;
}

function filterShortHiddenTrips(hiddenTrips: HiddenTrip[]) {
  return hiddenTrips.filter(
    (hiddenTrip) => hiddenTrip.first_stop_name !== hiddenTrip.last_stop_name
  );
}

function useFetchHiddenTrips() {
  const { data, error } = useFetch<{
    hidden_trips: HiddenTrip[];
    services: Service[];
  }>(`${BACKEND_BASE_URL}/api/trips/hidden`);

  const [hiddenTrips, setHiddenTrips] = useState<HiddenTrip[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setHiddenTrips(
        sortHiddenTrips(filterShortHiddenTrips(data.hidden_trips))
      );
      setServices(data.services);
      setLoading(false);
    }
  }, [data]);

  return { hiddenTrips, services, loading, error };
}

export function useHiddenTrips() {
  const { hiddenTrips, services, loading, error } = useFetchHiddenTrips();

  const [localStorage, setLocalStorage] = useLocalStorage<{
    hiddenTrips: HiddenTrip[];
    services: Service[];
    timestamp: number;
  } | null>("hidden_trips", null);

  // update local storage after fetch
  useEffect(() => {
    if (!loading) {
      const timestamp = Date.now();
      setLocalStorage({ hiddenTrips, services, timestamp });
    }
  }, [loading, setLocalStorage, hiddenTrips, services]);

  if (
    loading &&
    localStorage !== null &&
    Date.now() - localStorage.timestamp < 3 * 60_000
  ) {
    return { ...localStorage, loading: false };
  }

  return { hiddenTrips, services, loading, error };
}
