import { isVehicleFavorite } from "../utils";
import { useLocalStorage } from "usehooks-ts";

const DEFAULT_FAVORITE_VEHICLES = [
  "HW101",
  "RZ",
  "HZ",
  "RP650",
  "RY899",
  "RG914",
  "HG999",
  "PA",
  "DA106-112",
  "BA113",
  "DO200-201",
  "DE601",
  "DE606",
  "DC700",
  "DC732-734",
  "BR737-741",
  "DO211-218",
  "DR743",
  "BU857-868",
  "BU977",
  "MC201",
  "MA202-203",
];

export function useFavoriteVehicles() {
  const [patterns, setPatterns] = useLocalStorage(
    "favorite_vehicles",
    DEFAULT_FAVORITE_VEHICLES
  );

  const isFavorite = (kmkId: string) => {
    return isVehicleFavorite(kmkId, patterns || []);
  };

  return { isFavorite, patterns, setPatterns };
}
