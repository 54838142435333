import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { getStopUrl } from "../utils";
import { useFavoriteStop } from "../hooks/useFavoriteStop";
import { useNavigate } from "react-router-dom";

interface StopModeButtonProps {
  stopName: string;
  mode: "departures" | "arrivals" | "planned" | "hidden";
}

export function StopModeButton({ stopName, mode }: StopModeButtonProps) {
  const { isFavorite, setFavorite } = useFavoriteStop(stopName);

  const navigate = useNavigate();

  return (
    <ButtonGroup className="mt-4 float-end">
      <DropdownButton
        as={ButtonGroup}
        title={
          mode === "departures"
            ? "Odjazdy"
            : mode === "arrivals"
            ? "Przyjazdy"
            : mode === "planned"
            ? "Wszystkie"
            : mode === "hidden"
            ? "Zjazdy/wyjazdy"
            : "???"
        }
        variant="outline-primary"
        align="end"
      >
        <Dropdown.Item
          eventKey="1"
          onClick={() => navigate(getStopUrl(stopName, "departures"))}
        >
          Odjazdy
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="2"
          onClick={() => navigate(getStopUrl(stopName, "arrivals"))}
        >
          Przyjazdy
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="3"
          onClick={() => navigate(getStopUrl(stopName, "hidden"))}
        >
          Zjazdy/wyjazdy
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="4"
          onClick={() => navigate(getStopUrl(stopName, "planned"))}
        >
          Wszystkie
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="4" onClick={() => setFavorite(!isFavorite)}>
          {isFavorite ? "Usuń z ulubionych" : "Dodaj do ulubionych"}
        </Dropdown.Item>
      </DropdownButton>
    </ButtonGroup>
  );
}
