import "./FavoriteVehicles.css";

import { KeyboardEvent, useMemo, useState } from "react";
import { compareKmkId, isValidPattern } from "../utils";

import Container from "react-bootstrap/Container";
import { Error } from "../components/Error";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { KmkId } from "../components/KmkId";
import PageTitle from "../components/PageTitle";
import Spinner from "react-bootstrap/Spinner";
import { useFavoriteVehicles } from "../hooks/useFavoriteVehicles";
import { useVehicles } from "../hooks/useVehicles";

export function FavoriteVehicles() {
  const { vehicles, loading, error } = useVehicles();

  const { isFavorite, patterns, setPatterns } = useFavoriteVehicles();

  const [value, setValue] = useState("");

  const kmkIds = useMemo(() => {
    if (vehicles === undefined) return [];
    const copy = vehicles.map((v) => v.kmk_id.substring(0, 5));
    copy.sort(compareKmkId);
    return copy;
  }, [vehicles]);

  const addPattern = (pattern: string) => {
    setPatterns((patterns) => [...patterns, pattern]);
  };

  const removeLastPattern = () => {
    setPatterns((patterns) => patterns.slice(0, patterns.length - 1));
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === " " || e.key === "Enter") {
      e.preventDefault();
      const pattern = value.toLocaleUpperCase();
      if (isValidPattern(pattern)) {
        addPattern(pattern);
        setValue("");
      }
    }
    if (e.key === "Backspace") {
      const input = e.target as HTMLInputElement;
      if (
        value.length === 0 ||
        (input.selectionStart === 0 && input.selectionEnd === 0)
      ) {
        e.preventDefault();
        removeLastPattern();
      }
    }
  };

  return (
    <Container>
      <PageTitle title="Ulubione" />
      <h1 className="my-4">Ulubione</h1>
      <InputGroup className="mb-3 border rounded px-1">
        {patterns.map((pattern) => (
          <InputGroup.Text
            key={pattern}
            className="border-0 bg-transparent px-0"
          >
            <div className="favorite-pill mx-1">
              <span className="prefix">{pattern.substring(0, 2)}</span>
              {pattern.substring(2)}
              <span
                className="favorite-pill-cross"
                onClick={() =>
                  setPatterns((patterns) =>
                    patterns.filter((p) => p !== pattern)
                  )
                }
              >
                ✕
              </span>
            </div>
          </InputGroup.Text>
        ))}
        <Form.Control
          type="text"
          value={value}
          placeholder="Numery taborowe"
          className="border-0 py-2 shadow-none px-2"
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleKeyDown}
          autoFocus
        />
      </InputGroup>
      {error ? (
        <Error error={error} />
      ) : loading ? (
        <Spinner animation="border" variant="primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <div className="favorite-grid mb-4">
          {kmkIds.map((kmkId) => {
            const favorite = isFavorite(kmkId);
            return (
              <div
                key={kmkId}
                className={
                  favorite
                    ? "text-center mb-1 bg-warning bg-opacity-50"
                    : "text-center mb-1"
                }
                onClick={
                  !favorite
                    ? () => setPatterns((patterns) => [...patterns, kmkId])
                    : undefined
                }
                style={{ cursor: favorite ? "auto" : "pointer" }}
              >
                <KmkId kmk_id={kmkId} link={false} />
              </div>
            );
          })}
        </div>
      )}
    </Container>
  );
}
