import Container from "react-bootstrap/Container";
import { Mode } from "../hooks/useStopPassages";
import PageTitle from "../components/PageTitle";
import { PlannedStopPassages } from "./PlannedStopPassages";
import { StopModeButton } from "../components/StopModeButton";
import { StopPassages } from "../components/StopPassages";
import { StopPointsMap } from "../components/StopPointsMap";
import { decodeStopName } from "../utils";
import { useParams } from "react-router-dom";

interface StopInnerProps {
  stopName: string;
  mode: Mode | "planned" | "hidden";
}

function StopInner({ stopName, mode }: StopInnerProps) {
  const decodedStopName = decodeStopName(stopName);

  return (
    <Container>
      <PageTitle title={decodedStopName} />
      <div className="row">
        <div className="col-lg-8">
          <StopModeButton stopName={decodedStopName} mode={mode} />
          <h1 className="my-4">{decodedStopName}</h1>
          {mode === "hidden" ? (
            <PlannedStopPassages stopName={stopName} onlyHidden />
          ) : mode === "planned" ? (
            <PlannedStopPassages stopName={stopName} />
          ) : (
            <StopPassages stopName={stopName} mode={mode} />
          )}
        </div>
        <div className="col-lg-4 my-4">
          <StopPointsMap stopName={stopName} />
        </div>
      </div>
    </Container>
  );
}

export function Stop() {
  const { stopName, mode } = useParams();

  if (stopName === undefined) {
    return null;
  }
  if (
    !(
      mode === "departures" ||
      mode === "arrivals" ||
      mode === "planned" ||
      mode === "hidden"
    )
  ) {
    return null;
  }

  return <StopInner stopName={stopName} mode={mode} />;
}
