import { Arrival } from "./Arrival";
import { Block } from "../hooks/useRoute";
import { Fragment } from "react";
import { KmkId } from "./KmkId";
import { Link } from "react-router-dom";
import { LiveIcon } from "./LiveIcon";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { RouteShortNameBox } from "./RouteShortNameBox";
import Table from "react-bootstrap/Table";
import Tooltip from "react-bootstrap/Tooltip";
import { formatTime } from "../utils";

export function UnknownVehicle() {
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>Pojazd niezalogowany</Tooltip>}
    >
      <small>⚠️</small>
    </OverlayTrigger>
  );
}

interface BlocksListProps {
  blocks: Block[];
}

export function BlocksList({ blocks }: BlocksListProps) {
  return (
    <Table size="sm" striped bordered hover className="table-td-align-middle">
      <thead>
        <tr>
          <th style={{ width: "15%" }} className="text-center">
            Od
          </th>
          <th style={{ width: "15%" }} className="text-center">
            Do
          </th>
          <th className="text-center">Linie</th>
          <th style={{ width: "20%" }} className="text-center">
            Pojazd
          </th>
          <th style={{ width: "25%" }}></th>
        </tr>
      </thead>
      <tbody>
        {blocks.map((block) => (
          <tr
            key={`${block.category}_${block.service_id}_${block.block_id}_${block.kmk_id}`}
          >
            <td className="text-center">{formatTime(block.start_time)}</td>
            <td className="text-center">
              {formatTime(block.end_time)}
              <Arrival />
            </td>
            <td className="text-center">
              {block.route_short_names.map((route_short_name) => (
                <Fragment key={route_short_name}>
                  <RouteShortNameBox routeShortName={route_short_name} />{" "}
                </Fragment>
              ))}
            </td>
            <td className="text-center">
              {block.kmk_id ? (
                <>
                  <KmkId kmk_id={block.kmk_id} />
                  <LiveIcon />
                </>
              ) : block.is_current ? (
                <UnknownVehicle />
              ) : null}
            </td>
            <td className="text-center">
              <Link to={`/blocks/${block.category}/${block.block_id}`}>
                Rozkład brygady
              </Link>
            </td>
          </tr>
        ))}
        {blocks.length === 0 && (
          <tr>
            <td colSpan={5} className="py-3 text-center text-secondary">
              Brak brygad.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
