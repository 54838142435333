import "./LiveIcon.css";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export function LiveIcon() {
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>Na żywo</Tooltip>}>
      <div className="live"></div>
    </OverlayTrigger>
  );
}
